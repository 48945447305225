import { useNavigate } from 'react-router-dom';
import { ButtonContainer, Button } from './styled';

const AuthButtons = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  return (
    <ButtonContainer>
      <Button onClick={handleLogin}>Login</Button>
      <Button onClick={handleSignup}>Sign Up</Button>
    </ButtonContainer>
  );
};

export default AuthButtons;