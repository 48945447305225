import styled from 'styled-components';
import { Container as BootstrapContainer } from 'react-bootstrap';

export const Container = styled(BootstrapContainer)`
    padding: 0;
    margin: 0;
`;

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background: url('/background.png') no-repeat center center;
    background-size: cover;
    color: white;
    position: relative;
`;

export const Content = styled.div`
    text-align: center;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
`;

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0;
    background-color: ${props => props.bgColor || 'transparent'} !important;
    min-height: 40vh; /* Ensures sections are the same height */
`;

export const Icon = styled.div`
    font-size: 5rem;
    margin-bottom: 20px;
    margin-top: -20px;
`;

export const Headline = styled.h3`
    font-size: 28px;
    font-family: 'Raleway', sans-serif;
    color: ${props => props.light ? '#a266ac' : '#e0b3e6'};
    margin-bottom: 20px;
`;

export const Description = styled.p`
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 40px;
`;

export const Title = styled.h1`
    font-size: 42px;
    font-family: 'Montserrat', sans-serif;
    color: #a3c9ff;;
    font-weight: bold;
    margin-bottom: 20px;
`;

export const SubTitle = styled.h2`
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    color: #e0b3e6;
    margin-bottom: 20px;
`;

export const CallToAction = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 800px;
    min-height: 320px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    margin: 0 auto;
`;

export const CallToActionText = styled.p`
    margin-bottom: 20px;
    p {
        font-size: 1.5rem;
        margin: 10px 0;
        color: white;
    }
`;

export const CallToActionButton = styled.button`
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #2883cd;
    border: none;
    cursor: pointer;
    display: inline-block;

    &:hover {
        background-color: #1a387a;
    }
`;

export const DashboardButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #2883cd;
  border: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #1a387a;
  }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

export const Input = styled.input`
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;

    &:focus {
        outline: none;
        border-color: #2883cd;
    }
`;

export const ThankYouMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure it takes full height */
    
    p {
        font-size: 1.5rem;
        margin: 10px 0;
        color: white;
    }
`;