import React from 'react';
import { Container } from './styled';
import withAuth from "../../hoc/withAuth";

const Dashboard = () => {
  return (
    <Container>
      <h2>Dashboard</h2>
    </Container>
  );
};

export default withAuth(Dashboard);