import React, {useState} from 'react';
import {
  Container,
  Content,
  Section,
  Icon,
  Headline,
  Description,
  CallToAction,
  CallToActionText,
  CallToActionButton,
  DashboardButton,
  Title,
  SubTitle,
  Background,
  Form,
  Input,
  ThankYouMessage
} from './styled';
import { FaHeadphones, FaDownload, FaFolder, FaTasks, FaLock, FaDesktop } from 'react-icons/fa';
import AuthButtons from '../AuthButtons';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from "../Shared/ErrorMessage";
import {emailSignup} from "../../services/api";
import {useFlag} from "../../context/FlagContext";

const LandingPage = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { isLandingPageOnly } = useFlag();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [djName, setDjName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setFormSubmitted(false);

    try {
      await emailSignup({ dj_name: djName, email: email });
      setFormSubmitted(true);
    } catch (error) {
      setError(error.description || error.message || 'An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Container fluid>
      <Background>
        <Content>
          <Title>GrooveFetch</Title>
          <SubTitle>Streamline Downloading Music, Elevate Your DJ Experience</SubTitle>
          <Description>
            GrooveFetch is the ultimate tool for DJs to effortlessly download their music.
            Use Spotify playlists to sync and download tracks from reliable music portals like Beatport.
            GrooveFetch saves you time and gets fresh tracks ready for your next set.
          </Description>
          {!isLandingPageOnly && (
            <>
              {auth.token ? (
                <DashboardButton onClick={handleDashboardClick}>Go to Dashboard</DashboardButton>
              ) : (
                <AuthButtons />
              )}
            </>
          )}

        </Content>
      </Background>

      <Section className="bg-light">
        <div className="container py-5">
          <div className="row text-center g-5">
            <div className="col-md-4">
              <Icon><FaHeadphones/></Icon>
              <Headline light>Effortless Playlist Integration</Headline>
              <Description>
                Connect your Spotify account and import playlists with just a few clicks.
                GrooveFetch makes it easy to download your monthly and yearly music playlists.
              </Description>
            </div>
            <div className="col-md-4">
              <Icon><FaDownload/></Icon>
              <Headline light>Quick Track Downloads</Headline>
              <Description>
                Download tracks from trusted sources like Beatport quickly and reliably.
                Say goodbye to the hassle of manual downloads and hello to more time preparing your next set.
              </Description>
            </div>
            <div className="col-md-4">
              <Icon><FaFolder/></Icon>
              <Headline light>Organized Local Folders</Headline>
              <Description>
                Automatically organize your downloaded tracks into your local library.
                Your tracks are downloaded ready to go.
              </Description>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-secondary text-light" bgColor="#2c2f33">
        <div className="container py-5">
          <div className="row text-center g-5">
            <div className="col-md-4">
              <Icon><FaDesktop/></Icon>
              <Headline>Analyze and Prepare</Headline>
              <Description>
                Automatically run analysis tools such as Mix in Key, add cue points, and do the first steps
                of music library import.
                GrooveFetch ensures your tracks are always performance-ready.
              </Description>
            </div>
            <div className="col-md-4">
              <Icon><FaTasks/></Icon>
              <Headline>Handoff to DJ Apps</Headline>
              <Description>
                Automatically import your downloaded music library to popular DJ software like Lexicon.
                Make sure your playlists are added and synced for playback in Rekordbox, Traktor, and Serato
              </Description>
            </div>
            <div className="col-md-4">
              <Icon><FaLock/></Icon>
              <Headline>Security and Scalability</Headline>
              <Description>
                GrooveFetch prioritizes the security of your data and the scalability of your music
                collection. Enjoy a seamless experience, no matter the size of your library.
              </Description>
            </div>
          </div>
        </div>
      </Section>

      <Section className="bg-light">
        <div className="container py-5 text-center">
          <CallToAction>
            {!formSubmitted ? (
              <>
              <CallToActionText>
                <p>Join the GrooveFetch community and take your DJing to the next level.</p>
                <p>Sign up to be notified when the app launches!</p>
              </CallToActionText>
                <Form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    placeholder="DJ Name"
                    value={djName}
                    onChange={(e) => setDjName(e.target.value)}
                    required
                  />
                  <Input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <CallToActionButton type="submit">Join</CallToActionButton>
                  <ErrorMessage message={error} />
                </Form>
              </>
            ) : (
              <ThankYouMessage>
                <p>Thank you for joining!</p>
                <p>We will contact you when the GrooveFetch is ready.</p>
              </ThankYouMessage>
            )}
          </CallToAction>
        </div>
      </Section>
    </Container>
  );
};

export default LandingPage;