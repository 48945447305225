import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
`;

export const Button = styled.button`
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #2883cd;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #1a387a;
    }
`;