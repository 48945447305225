import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavBarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 1rem;
  height: 60px;
  overflow: hidden;
`;

export const TitleLink = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Icon = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
  transform: scale(0.75);
  margin-right: 0.5rem;
`;

export const Title = styled.span`
  font-size: 1.5rem;
  margin-left: 0.5rem;
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  margin-right: 1rem;
`;

export const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.25rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const MailtoLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.25rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const LogoutButton = styled.button`
  color: white;
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
