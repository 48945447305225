import React, {useEffect, useState} from 'react';
import { login as loginUser } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Input, Button } from './styled';
import ErrorMessage from "../Shared/ErrorMessage";
import {useAuth} from "../../context/AuthContext";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { login, auth } = useAuth();

  useEffect(() => {
    if (auth.token) {
      navigate('/dashboard');
    }
  }, [auth, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const { access_token, user } = await loginUser({ username, password });
      login(access_token, user);
      navigate('/dashboard');
    } catch (error) {
      setError(error.description || error.message);
    }
  };

  return (
    <Container>
      <h2>Login Page</h2>
      <Form onSubmit={handleSubmit}>
        <Input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
        <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <Button type="submit">Login</Button>
        <ErrorMessage message={error} />
      </Form>
    </Container>
  );
};

export default Login;