import React, {useEffect, useState} from 'react';
import {signup} from '../../services/api';
import {useNavigate} from 'react-router-dom';
import {Button, Container, Form, Input} from './styled';
import ErrorMessage from "../Shared/ErrorMessage";
import {useAuth} from "../../context/AuthContext";

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { auth } = useAuth();

  useEffect(() => {
    if (auth.token) {
      navigate('/dashboard');
    }
  }, [auth, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await signup({username, email, password});
      navigate('/dashboard');
    } catch (error) {
      setError(error.description || error.message);
    }
  };

  return (
    <Container>
      <h2>Signup Page</h2>
      <Form onSubmit={handleSubmit}>
        <Input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
        <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <Button type="submit">Sign Up</Button>
        <ErrorMessage message={error} />
      </Form>
    </Container>
  );
};

export default Signup;