import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import NavBar from "./components/NavBar";
import {useFlag} from "./context/FlagContext";

const App = () => {
  const { isLandingPageOnly } = useFlag();
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {isLandingPageOnly ? (
          <Route path="*" element={<Navigate to="/" />} />
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default App;