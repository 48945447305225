import React, { createContext, useContext, useState, useEffect } from 'react';

const FlagContext = createContext();

export const useFlag = () => {
  return useContext(FlagContext);
};

export const FlagProvider = ({ children }) => {
  const [isLandingPageOnly, setIsLandingPageOnly] = useState(false);

  useEffect(() => {
    const landingPageOnly = process.env.REACT_APP_LANDING_PAGE_ONLY === 'true';
    setIsLandingPageOnly(landingPageOnly);
  }, []);

  return (
    <FlagContext.Provider value={{ isLandingPageOnly }}>
      {children}
    </FlagContext.Provider>
  );
};
