import axios from 'axios';
import { logout } from '../context/AuthContext';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    // Handle the error
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Auth error:', error.response.data);
        logout();
        return Promise.reject(error.response.data);
      } else {
        // Request made and server responded
        console.error('Backend error:', error.response.data);
        return Promise.reject(error.response.data);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Network error:', error.request);
      return Promise.reject({ message: 'Network error. Please try again.' });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
      return Promise.reject({ message: 'An unexpected error occurred. Please try again.' });
    }
  }
);

export const signup = async (userData) => {
  const response = await api.post('/signup', userData);
  return response.data;
};

export const login = async (userData) => {
  const response = await api.post('/login', userData);
  return response.data;
};

export const emailSignup = async (userData) => {
  const response = await api.post('/email-signup', userData);
  return response.data;
};
