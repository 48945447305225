import React from 'react';
import {NavBarContainer, Title, NavLinks, NavLink, LogoutButton, MailtoLink, TitleLink, Icon} from './styled';
import {useAuth} from "../../context/AuthContext";
import { useFlag } from '../../context/FlagContext';

const NavBar = () => {
  const { auth, logout } = useAuth();
  const { isLandingPageOnly } = useFlag();

  return (
    <NavBarContainer>
      <TitleLink to="/">
        <Icon src={`${process.env.PUBLIC_URL}/logo.png`} alt="GrooveFetch Icon" />
        <Title>GrooveFetch</Title>
      </TitleLink>
      <NavLinks>
        {!isLandingPageOnly && (
          <>
            {auth.token ? (
              <>
                <NavLink to="/dashboard">Dashboard</NavLink>
                <LogoutButton onClick={logout}>Logout</LogoutButton>
              </>
            ) : (
              <>
                <NavLink to="/login">Login</NavLink>
                <NavLink to="/signup">Signup</NavLink>
              </>
            )}
          </>
        )}
        <MailtoLink href="mailto:djraamusic@gmail.com">Contact</MailtoLink>
      </NavLinks>
    </NavBarContainer>
  );
};

export default NavBar;
