import React from 'react';
import PropTypes from 'prop-types';
import {ErrorText} from './styled'

const ErrorMessage = ({ message }) => {
  if (!message) return null;
  return (
    <ErrorText>
      {message}
    </ErrorText>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default ErrorMessage;