import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../context/AuthContext';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const {auth} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (!auth.token) {
        navigate('/login');
      }
    }, [auth, navigate]);

    if (!auth.token) {
      return null; // or a loading spinner
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
